export default {
  calcForm: {
    has_bill_of_sale: true,
    official_income: null,
    calc_user_name: '',
    calc_phone_number: '',
    term: 10,
    creditRate: 14.5,
    creditAmount: 0,
    firstPayment: 10000,
    firstPaymentRate: 15
  },
  searchForm: {
    'popup-header': true,
    currentTabId: 0,
    currentSortType: '1',
    price_from: '',
    locationIds: [],
    error: false,
    resetPopupHandler: null,
    resetButtonIsDisabled: false,
    locationsResetButtonIsDisabled: false,
    chosenLocationNames: [],
    currentCityIsDeferred: false,
    currentCity: '',
    defaultState: {
      cityId: '1',
      leased: 'false',
      categoryId: '1'
    },
    currentState: null,
    isLoadingResultByNumber: false,
    resultByNumber: { hasAnyResult: false, result: null },
    isClickedEmptyResultByNumber: false,
    categoryIds: []
  },
  drawerUI: {
    isVisible: false
  },
  errorUI: {
    isError: false
  },
  blockerDetector: {
    isBlockerActive: false
  },
  authModal: {
    isVisible: false
  },
  sideMenuModal: {
    isVisible: false
  },
  restoreAlertModal: {
    isVisible: false
  },
  promotionModalView: {
    isVisible: false
  },
  successPromotionModalView: {
    isVisible: false,
    name: ''
  },
  failurePaymentModalView: {
    isVisible: false,
    name: ''
  },
  addNewItemModal: {
    isVisible: false,
    cityHasLocations: true,
    isOpenAuthWindow: false,
    creationResetButtonIsDisabled: false
  }
};
